var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showAccounts && _vm.showFullView)?_c('b-dropdown-select-account',{attrs:{"options":_vm.userAccountsFiltered,"hide-account-switchers":_vm.hideAccountSwitchers,"show-account-list":_vm.isMoreThanOneAdvertiser,"is-old-flip-header":_vm.isOldFlipHeader},on:{"close":_vm.hideAccountSwitchers,"selected-option":_vm.onSelectAccount,"on-account-settings-click":_vm.onAccountSettingsClick}}):_vm._e(),_c('div',{staticClass:"ln_acct_container",on:{"click":_vm.toggleAccounts}},[(_vm.showFullView)?_c('div',{class:{
        ln_acct_txt: !_vm.isOldFlipHeader,
        ln_acct_txt_flip_header: _vm.isOldFlipHeader,
      }},[_c('div',{ref:"longActTooltip",staticClass:"ln_acct_name_container box_tooltip",on:{"mouseover":function($event){_vm.showTooltip = _vm.shouldShowTooltip},"mouseleave":function($event){_vm.showTooltip = false}}},[_c('div',{ref:"nameContainer",class:{
            'custom-bg-title': _vm.isMniAccount,
            ln_acct_name: !_vm.isOldFlipHeader,
            ln_acct_name_flip_header: _vm.isOldFlipHeader,
          },attrs:{"title":_vm.getAccName()}},[_vm._v(" "+_vm._s(_vm.getAccName())+" ")]),_c('b-tooltip',{attrs:{"tooltip-text":`${_vm.getAccName()}`}})],1),_c('div',{staticClass:"ln_acct_label",class:{ 'custom-ln_acct_label': _vm.isMniAccount }},[_vm._v(" Current Account ")])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }